import { useNavigator } from "helpers/hooks/use-navigator";
import React from "react";
import { ArrowLeftIcon } from "../icons/arrow-left";
import "./simple-page-header.scss";
import classNames from "classnames";

export const SimplePageHeader = ({
  title,
  menu,
  icon,
  disableBack,
  useNavigationPush,
  invert,
}: {
  title: string | JSX.Element;
  icon?: JSX.Element;
  menu?: JSX.Element;
  disableBack?: boolean;
  useNavigationPush?: boolean;
  invert?: boolean;
}) => {
  const navigator = useNavigator();
  return (
    <div
      className={classNames("header flex flex-row  items-center  p-4", {
        "primary-gradient-bg text-white": !invert,
        "text-primary bg-white": invert,
      })}
      style={{ height: "5rem" }}
    >
      {!disableBack && (
        <div
          className="header-back-button"
          onClick={() => {
            if (useNavigationPush) {
              const x = navigator.goBack();
              // console.log('x', x)
              // navigator.navigateTo("/", []);
            } else {
              navigator.navigateTo("/", []);
            }
          }}
        >
          <ArrowLeftIcon size={"1.3rem"} />
        </div>
      )}
      {icon && icon}

      <div className="title flex-1 text-lg font-semibold  pl-2 overflow-hidden">
        {title}
      </div>
      {menu && menu}
    </div>
  );
};
