import { EntityServices } from "@sdk/utils/entity.service";
import { axios, AxiosResponse } from "../axios";
import { iCheckList, iCheckListInstance } from "./checklists-models";

export class CheckListsServices {
  getCurrentConfig = () => {
    return this.config;
  };

  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  checkLists = Object.assign(
    {},
    {
      getChecklistById: async (checkListId: string) => {
        const results: AxiosResponse<iCheckListInstance> = await axios.get(
          this.config.basePath +
            `/chat-widget/sessions/checklists/${checkListId}`,
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      getAllCheckLists: async () => {
        const results: AxiosResponse<iCheckListInstance[]> = await axios.get(
          this.config.basePath + `/chat-widget/sessions/checklists`,
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      markStepAsCompleted: async (
        checkListInstanceId: string,
        req: { stepId: string; status?: boolean }
      ) => {
        const results: AxiosResponse<{ success: boolean }> = await axios.post(
          this.config.basePath +
            `/chat-widget/sessions/checklists/${checkListInstanceId}/mark-as-completed`,
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          }
        );
        return results.data;
      },
      getCheckListConfig: async (id: string) => {
        const results: AxiosResponse<iCheckList> = await axios.get(
          this.config.basePath +
            `/chat-widget/sessions/checklists/${id}/config`,
          {
            headers: {},
          }
        );
        return results.data;
      },
      getCheckListConfigById: async (id: string) => {
        const results: AxiosResponse<iCheckList> = await axios.get(
          this.config.basePath +
            `/chat-widget/sessions/checklists/config/${id}`,
          {
            headers: {},
          }
        );
        return results.data;
      },
    }
  );
}
