import { iStore } from "../../store.model";

export const selectAudioMuteState = (state: iStore) =>
  state.appState.audio.isMuted;

export const selectAudioState = (state: iStore) =>
  !state.appState.audio.isMuted;

export const selectWidgetMaximizedState = (state: iStore) =>
  state.appState.isMaximized;

export const selectWidgetMaximizedAtLeastOnce = (state: iStore) =>
  state.appState.isMaximizedAtLeastOnce;

export const selectSocketConnectionState = (state: iStore) =>
  state.appState.isSocketConnected;

export const selectAppInitTime = (state: iStore) => state.appState.appInitTime;

export const persistorSelector = (state: any) => state._persist;

export const selectCampaignState = (state: iStore) =>
  state.appState.campaignState;

export const selectLastVisitedTab = (state: iStore) =>
  state.appState.lastVisitedTab;

export const selectPreferredSubjectLine = (state: iStore) =>
  state.appState.preferredSubjectLine;

export const selectPreInitConfig = (state: iStore) =>
  state.appState.preInitConfig;
