import classNames from "classnames";
import { ToolTipX } from "components/common/tooltip/tooltip";
import { getUserName } from "helpers/get-user-name";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectWidget,
  selectWidgetColorPallet,
} from "store/modules/session/session.selectors";
import { selectUserById } from "store/modules/users/users.selectors";
import "./user-avatar.scss";
import { useQueryWithStore } from "store/store.hooks";
import {
  _loadUserById,
  loadUserBydId,
} from "store/modules/users/users.helpers";

export const UserAvatar = ({
  size = 48,
  userId,
  badge,
  isBordered,
  style,
}: {
  size?: number | string;
  userId: string;
  badge?: number;
  isBordered?: boolean;
  style?: React.CSSProperties;
}) => {
  const widget = useSelector(selectWidget);
  // const user = useSelector(selectUserById(userId));
  const { state: user } = useQueryWithStore(
    selectUserById(userId),
    loadUserBydId(userId, widget?.id!),
    [userId]
  );
  const defaultAvatar = "/assets/default-avatar.png";
  const theme = useSelector(selectWidgetColorPallet);

  const { userName, avatarUrl } = useMemo(() => {
    const userName = getUserName(user) || "Team Member";
    const avatarUrl = user?.data.avatar || defaultAvatar;

    if (userId === "CC" || userId === "cc" || userId === "SYSTEM") {
      return {
        userName: widget?.configurations.chatBotProfile?.name || userName,
        avatarUrl: widget?.configurations.chatBotProfile?.avatar || avatarUrl,
      };
    }
    return {
      userName,
      avatarUrl,
    };
  }, [
    user,
    userId,
    widget?.configurations?.chatBotProfile?.avatar,
    widget?.configurations?.chatBotProfile?.name,
  ]);

  return (
    <div className="user-avatar" style={style}>
      {badge && <div className="text-white bg-red-700">{badge}</div>}
      <ToolTipX content={userName}>
        <div
          style={{
            width: size,
            height: size,
            ...(isBordered
              ? {
                  borderWidth: 2,
                  borderColor: theme.primary,
                }
              : {}),
            background: `url(${defaultAvatar})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className={classNames("avatar-container", {
            online: user?.metaData.isAvailable,
          })}
        >
          <img src={avatarUrl} alt="" style={{ transform: "scale(1.1)" }} />
        </div>
      </ToolTipX>
    </div>
  );
};
